import { EMAIL_REGEX } from "src/constants";
import  * as yup from "yup";

const spaceCheckYupValidation = (val?: string) => val ? (!/^\s*$/.test(val)) : false

export const AddUserSchema = yup.object({
  firstname: yup
    .string()
    .required(`First name is required`)
    .max(35, `First name should only be 35 characters long`)
    .test("whiteSpaceRemoval", "First name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
  middlename: yup
    .string()
    .max(35, `Middle name should only be 35 characters long`)
    .when({
      is: (val: string) => val && val.length > 0,
      then: (schema) => schema
        .test("whiteSpaceRemoval", "Middle name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    }),
  lastname: yup
    .string()
    .required("Last name is required")
    .max(35, `Last name should only be 35 characters long`)
    .test("whiteSpaceRemoval", "Last name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
  email: yup
    .string()
    .required('Email is required')
    .max(100, 'Email should be less than 100 characters')
    .test('EmailVerification', `Email should be valid`, (val) => spaceCheckYupValidation(val) && (EMAIL_REGEX.test(val))),
});

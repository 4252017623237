import * as yup from "yup";

import { EMAIL_REGEX } from "../constants";

export const forgotpasswordSchema = yup.object({
  email:  yup
  .string()
  .required('Email is required')
  .max(100, 'Email should be less than 100 characters')
  .test('EmailVerification', `Email should be valid`, (val) => (EMAIL_REGEX.test(val))),
});

// import { HeaderElement, HeaderElementType } from "../types";

import {HeaderElement, HeaderElementType } from "src/types";

const resourcesHeader = {
  title: 'Resources',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Reprise Enrollment Form',
      redirectLink: '/assets/patient-enrollment.pdf',
    },
    {
      title: 'CPT Coding Info Sheet',
      redirectLink: '/assets/CPT Coding Info Sheet.pdf',
    },
    {
      title: 'Miro3D Wound Matrix Brochure',
      redirectLink:'/assets/Miro3D-Brochure.pdf'
    },
    {
      title: 'Miro3D Wound Matrix Coding and Billing',
      redirectLink:'/assets/Miro3D-and-MiroTract-Coding-and-Billing-Guide.pdf'
    },
    {
      title: 'Miro3D Wound Matrix Unit Guide',
      redirectLink:'/assets/Miro3D-Billing-Units-Guide.pdf'
    },
    {
      title: 'Miro3D Wound Matrix IFU',
      redirectLink:'/assets/Miro3D-Instructions-for-Use-(IFU).pdf'
    },
    {
      title:'MiroDerm Biologic Wound Matrix Brochure',
      redirectLink:'/assets/MiroDerm-Brochure.pdf'

    },
    {
      title:'MiroDerm Biologic Wound Matrix Coding and Billing',
      redirectLink:'/assets/MiroDerm-Coding-and-Billing-Guide.pdf'

    },{
      title:'MiroDerm Biologic Wound Matrix IFU',
      redirectLink:'/assets/MiroDerm-Fenestrated-Fenestrated-Plus-Instructions-for-Use-(IFU).pdf'

    },
    {
      title:'MiroTract Wound Matrix Brochure',
      redirectLink:'/assets/MiroTract-Brochure.pdf'

    },
    {
      title:'MiroTract Wound Matrix Coding and Billing',
      redirectLink:'/assets/MiroTract Coding and Billing Guide.pdf'

    },
    {
      title:'MiroTract Wound Matrix Billing Units Guide',
      redirectLink:'/assets/MiroTract-Billing-Units-Guide.pdf'

    },
    {
      title:'MiroTract Wound Matrix IFU',
      redirectLink:'/assets/MiroTract-Instructions-for-Use-(IFU).pdf'

    },
  ]
};
export const PublicHeader: HeaderElement[] = [
  {
    title: 'Practice Registration',
    redirectLink: '/enrollments/new',
    actionType: HeaderElementType.ROUTE,
  },
];

export const AuthenticatedManufactureHeader: HeaderElement[] = [
  
  {
    title: 'Statistics',
    redirectLink: '/statistics',
    actionType: HeaderElementType.ROUTE,
  },
  {} as unknown as HeaderElement,
  resourcesHeader,
];

export const AuthenticatedHCPHeader: HeaderElement[] = [
  {
    title: 'Cases',
    redirectLink: '/cases',
    actionType: HeaderElementType.ROUTE,
  },
  {
    title: 'Submit Request',
    redirectLink: '/cases/new',
    actionType: HeaderElementType.ROUTE,
  },
  resourcesHeader,
];



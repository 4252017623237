import * as yup from "yup";

import { EMAIL_REGEX } from "../constants";

export const NewEnrollmentSchema = yup.object({
  npi: yup
    .string()
    .when({
      is: (val:string) => val && val.length > 0,
      then: (schema) =>
        schema
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, "Invalid NPI")
          .max(10, "Invalid NPI")
          // .test('range', 'NPI Invalid', (val) => {
          //   const numberedVal = Number(val)
          //   if (!isNaN(numberedVal)) {
          //     if (numberedVal >= 1000000000 && numberedVal <= 9999999999) return true;
          //   }
          //   return false
          // })
          ,
    }),

  enrollment_practices_attributes: yup
    .array()
    .of(
      yup.object({
        practice_name: yup
          .string()
          .required(`Practice Name is required`)
          .max(120, `Practice Name should only be 120 characters long`),
        npi: yup
          .string()
          .required(`NPI is required`)
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, `NPI Invalid`)
          .max(10, `NPI Invalid`)
          // .test('range', 'NPI Invalid', (val) => {
          //   const numberedVal = Number(val)
          //   if (!isNaN(numberedVal)) {
          //     if (numberedVal >= 1000000000 && numberedVal <= 9999999999) return true;
          //   }
          //   return false
          // }),
          ,
        tax_id: yup
          .string()
          .max(9, `Tax id Invalid`),
        phone_number: yup
          .string()
          .required(`Phone Number is required`)
          .min(10, `Phone Number Invalid`)
          .max(10, `Phone Number Invalid`)
          .test('range', 'Phone Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          })
          ,
        fax_number: yup
          .string()
          .required(`Fax Number is required`)
          .min(10, `Fax Number Invalid`)
          .max(10, `Fax Number Invalid`)
          .test('range', 'Fax Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        street: yup
          .string()
          .required(`Street is required`),
        suite: yup
          .string(),
        city: yup
          .string()
          .required(`City is required`),
        state: yup
          .string()
          .required(`State is required`),
        zip: yup
          .string()
          .required(`Zip code is required`)
          .max(10, `Zip code Invalid`)
          .test("containAllDigits", "Zip code should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
      }),
    )
    .required(`Practice data is required`),

  enrollment_contacts_attributes: yup
    .array().of(
      yup.object({
        firstname: yup
          .string()
          .required(`First Name is required`)
          .max(35, `Name is too long`),
        lastname: yup
          .string()
          .required(`Last Name is required`)
          .max(35, `Name is too long`),
        middlename: yup
          .string()
          .max(35, `Name is too long`),
        email: yup
          .string()
          .required(`Email is required`)
          .max(100, 'Email should be less than 100 characters')
          .test('EmailVerification', `Email should be valid`, (val) => (EMAIL_REGEX.test(val))),
      })
    )
    .required(`User data is required`),

  enrollment_doctors_attributes: yup
    .array()
    .of(
      yup.object({
        npi: yup
          .string()
          .required(`NPI is required`)
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, `Npi Invalid`)
          .max(10, `Npi Invalid`),
        tax_id: yup
          .string()
          .max(9, `tax ID Invalid`),
        firstname: yup
          .string()
          .required(`First Name is required`)
          .max(35, `Name should only be 35 characters long`),
        lastname: yup
          .string()
          .required(`Last Name is required`)
          .max(35, `Name should only be 35 characters long`),
        middlename: yup
          .string()
          .max(35, `Name should only be 35 characters long`),
        ref_taxonomy_id: yup
          .string()
          .required(`Taxonomy of our specific Provider is required`),
        phone_number: yup
          .string()
          .required(`Phone Number is required`)
          .min(10, `Phone Number Invalid`)
          .max(10, `Phone Number Invalid`)
          .test('range', 'Phone Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        fax_number: yup
          .string()
          .required(`Fax Number is required`)
          .min(10, `Fax Number Invalid`)
          .max(10, `Fax Number Invalid`)
          .test('range', 'Fax Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        dea: yup
          .string()
          .when({
            is: (val:string) => val && val.length > 0,
            then: (schema) =>
              schema
                .min(9, "DEA Invalid")
                .max(9, "DEA Invalid")
                .test('CheckForInvalidChars', 'DEA Invalid', (val) => (val ? /^[a-z0-9]+$/i.test(val) : true)),
          }),
        sln: yup
          .string(),
        ptan: yup
          .string(),
        email: yup
          .string()
          .max(100, 'Email should be less than 100 characters')
          .test('EmailVerification', `Email should be valid`, (val) => (val ? EMAIL_REGEX.test(val) : true)),
      }),
    )
    .required(`Prescriber data is required`),
});

import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { forgotpasswordSchema } from "../../validation/forgotPassword";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { ForgotPasswordResponseType } from "./types";
import { LoaderContext } from "../../context/loaderContext";

const HubForgotPassword: FC = (): JSX.Element => {
  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(forgotpasswordSchema),
  });
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);





  const { loading, error, data, mutate, removeErrorAndData } = useFetch<null, null, ForgotPasswordResponseType>(APIEndpoints.USER_FORGOT_PASSWORD);

  useEffect(() => {
    if (data || error) {
      hideLoader();
      if (data) {
        const { message } = data;
        showToast([message], ToastTypes.SUCCESS);
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const submitForm = async (values: { email: string }) => {
    setLoader();
    if ((error && error.length > 0) || data) removeErrorAndData();

    mutate({ user: values });
  };




  return (
    <div className="container-fluid login-sec d-flex flex-fill flex-column justify-content-between overflow-auto">
      <div className="container d-flex flex-fill align-items-center justify-content-center p-4">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 pb-5 px-4">
          <div className="card">
            <div className="card-header p-3">
              <h4 className="m-0">Forgot Your Password?</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(submitForm)} className="forgot-formlabel">
                <Controller 
                  name="email" 
                  control={control} 
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField 
                      inputId="email" 
                      isRowBased={false} 
                      isRequired={true} 
                      field={field} 
                      error={error}
                      inputLabel="Email"
                    />
                  )}
                />
                <button disabled={loading} className="btn btn-primary" type="submit">
                  {loading ? (<i className="spinner-border me-2"></i>) : null}
                  Send me reset password instruction
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterText footerType="white"/>
    </div>
  );
};

export default HubForgotPassword;
